import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Seo from '../components/Seo'
import Faq from '../components/Faq/index'
import PageTitle from '../components/Blocks/PageTitle'
import { CaretRight } from 'phosphor-react'

const ContactTemplate = ({ title, content, faq, locations }) => (
  <div className="w-full">
    <PageTitle title={title} />
    <div className="w-full locations grid grid-cols-1 md:grid-cols-2">
      {locations.nodes.map((location, i) => (
        <div key={location.slug} className="">
          <div
            style={{
              backgroundImage: `url(${location.featuredImage?.node.mediaItemUrl})`,
            }}
            className="bg-cover bg-center relative bg-gray-300 h-96 "
          >
            <div className="h-5 absolute bottom-5 bg-brand-blue opacity-40 w-full" />
            <div className="h-5 absolute bottom-0 bg-brand-blue opacity-80 w-full" />
          </div>

          <div className="bg-brand-blue w-full p-7 text-center">
            <Link to={`/contact-us/${location.slug}/`}>
              <h4 className="text-white uppercase text-4xl font-thin">
                {location.title}
              </h4>
            </Link>
          </div>
          <div className="p-10 md:p-20 flex flex-col items-center justify-center">
            <p className="text-center mx-auto text-lg">
              {location.locationInformation?.locationAddress}
            </p>
            <a
              href={`tel:${location.locationInformation?.locationPhone}`}
              className="text-center mx-auto text-lg block font-thin underline"
            >
              {location.locationInformation?.locationPhone}
            </a>
            <a
              href={`mailto:${location.locationInformation?.locationEmail}`}
              className="text-center mx-auto text-lg block font-thin underline"
            >
              {location.locationInformation?.locationEmail}
            </a>
            <a
              href={location.locationInformation?.locationDirectionsLink}
              target="_blank"
              rel="norefferer"
              className="btn uppercase my-5"
            >
              Get directions
            </a>
            <Link
              to={`/contact-us/${location.slug}/`}
              className="btn uppercase my-5f"
            >
              Location Information
            </Link>
          </div>
        </div>
      ))}
    </div>
    <div
      className={'transition-all page-content'}
      dangerouslySetInnerHTML={{ __html: content }}
    />
    <div className="bg-gray-500 py-10 text-center px-8 transition-all hover:bg-brand-blue duration-500 flex justify-center">
      <Link
        to="/new-car-lease/"
        className="text-lg text-white uppercase cursor-pointer  font-light"
      >
        <div className="flex-col md:flex-row flex gap-x-2 items-center">
          <p>Interested in leasing?</p>{' '}
          <span className="flex gap-x-2 items-center">
            Learn more <CaretRight />
          </span>
        </div>
      </Link>
    </div>
  </div>
)

const Page = ({ data, pageContext }) => {
  const { wpPage: page, allWpLocation: locations } = data
  return (
    <Layout pageContext={pageContext}>
      {/* <Seo seo={page.seo} /> */}
      <Helmet>
        <script type="application/ld+json">
          {`[{"@context":"https://schema.org","@type":"ContactPage","mainEntityOfPage":{"@type":"WebPage","@id":"https://www.dmautoleasing.com/contact-us/"},"url":"https://www.dmautoleasing.com/contact-us/","headline":"Contact | DFW, Grand Prairie, and Houston | D&M Leasing","description":"Get in touch with your local D&M Leasing location. Make no down payment and zero payments for 90 days with our EZ Lease. Call now for a quote!","publisher":{"@type":"Organization","name":"D&amp;M Auto Leasing","url":"https://www.dmautoleasing.com"}},{"@context":"https://schema.org","@graph":[{"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"https://www.dmautoleasing.com/#Main Menu","name":"Home","url":"https://www.dmautoleasing.com/"}]},{"@context":"https://schema.org","@type":"Review","@id":"https://www.dmautoleasing.com/contact-us/#Review","itemReviewed":{"@type":"LocalBusiness","image":[]},"name":"D&amp;M Auto Leasing","author":{"@type":"Person","name":"Darren Hasley"}}]`}
        </script>
      </Helmet>
      <ContactTemplate
        title={page.title}
        content={page.content}
        faq={page.faq}
        locations={locations}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ContactPageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
    allWpLocation(sort: { order: ASC, fields: date }) {
      nodes {
        id
        title
        slug
        content
        locationInformation {
          locationDirectionsLink
          locationAddress
          locationEmail
          locationPhone
        }
        featuredImage {
          node {
            id
            altText
            mediaItemUrl
          }
        }
      }
    }
  }
`
